import type { DistrictStatus, TicketChannel } from '@prisma/client'
import { ViewGrouping } from '@prisma/client'
import type { TFunction } from 'i18next'

import { isPojo, type Pojo } from './types'

export const capitalize = (val: string) => {
  if (!val) return ''
  return `${val.slice(0, 1).toUpperCase()}${val.slice(1)}`
}

export const deepCapitalizeKeys = (pojo: Pojo): Pojo => {
  const mapValue = (value: unknown): unknown => {
    if (Array.isArray(value)) return value.map(mapValue)
    if (isPojo(value)) return deepCapitalizeKeys(value as Pojo)
    return value
  }

  return Object.fromEntries(Object.entries(pojo).map(([key, value]) => [capitalize(key), mapValue(value)]))
}

/**
 * Convert a given string to title case
 *
 * Example Input: "hello world"
 * Example Output: "Hello World"
 */
export const toTitleCase = (str: string) => {
  // The regex \w* matches every word character in the input string
  return str.replace(/\w*/g, (txt: string): string => {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
  })
}

/**
 * Returns the initials (default is max 2 characters) of a given string.
 * For strings with spaces, the initials will be the first character of the first and last word.
 *   Example: "Jane R. Doe" => "JD"
 * For strings with dots, the initials will be the first character the first two words separated by dots.
 * This is particularly useful for email addresses.
 *  Example: "jane.r.doe@gmail.com" => "JR"
 * For strings with no spaces or dots, the initials will be the first two characters of the string.
 * Example: "Jane" => "JA"
 * Similarly to all the above, setting the max value to 3 would result in the following:
 *   Example: "Jane R. Doe", max=3 => "JRD"
 */
export const getInitials = (value: string, max: number = 2) => {
  // We often pass in email addresses to this function, and we don't want the domain name be part of the initials.
  // We also only want letters (from any language, so including e.g. É and Ж), dots and spaces to be part of the initials, so we remove any other characters.
  const sanitizedString = value.split('@')[0].replace(/[^\p{L}\s.]/gu, '')

  const wordsSeparatedBySpace = sanitizedString.split(' ')
  if (wordsSeparatedBySpace.length > 1) {
    const allWords = wordsSeparatedBySpace.slice(0, max - 1).map((a) => a.charAt(0).toUpperCase())
    allWords.push(wordsSeparatedBySpace[wordsSeparatedBySpace.length - 1].charAt(0).toUpperCase())
    return allWords.join('')
  }

  const wordsSeparatedByDot = sanitizedString.split('.')
  if (wordsSeparatedByDot.length > 1) {
    return wordsSeparatedByDot
      .slice(0, max)
      .map((a) => a.charAt(0).toUpperCase())
      .join('')
  }

  return sanitizedString.slice(0, max).toUpperCase()
}

/** Returns the abbreviation of a given string based on our abbreviation rules for group names.
 * For one word strings, the abbreviation is the first three letters of the string.
 * Example: "Jane" => "JAN"
 * For two word strings, the abbreviation is the first letter of the first word and the first two letters of the second word.
 * Example: "Jane Doe" => "JDO"
 * The abbreviation is the first letter of the first three words of the string.
 * Example: "Jane R. Doe" => "JRD"
 */

export const getAbbreviation = (value: string): string => {
  const items = value.split(/\s/).filter(Boolean)

  let abbr = ''
  if (items.length === 1) {
    abbr = value.slice(0, 3)
  } else if (items.length === 2) {
    abbr = `${value.slice(0, 1)}${items[1].slice(0, 2)}`
  } else if (items.length > 2) {
    abbr = items
      .slice(0, 3)
      .map((item) => item[0])
      .join('')
  }
  return abbr.toUpperCase()
}

export const channelToReadableName = (channel: TicketChannel, t: TFunction) => {
  return t(`tickets.channels.${channel}`)
}

export const districtStatusToReadableName = (status: DistrictStatus, t: TFunction) => {
  return t(`contact.districtStatus.${status}`)
}

export const viewGroupingToReadableName = (viewGrouping: ViewGrouping, t: TFunction) => {
  // Ungrouped views don't have grouping names
  return viewGrouping === ViewGrouping.PrimaryUngrouped || viewGrouping === ViewGrouping.SecondaryUngrouped
    ? ''
    : t(`tickets.views.${viewGrouping}`)
}

export const UNASSIGNED_TICKET = 'unassigned'
export const CREATE_NEW_TICKET_PARAM = 'newTicket'
export const CREATE_NEW_REPLY_PARAM = 'newReply'
export const NEW_REPLY_DRAFT_ID = 'draft'
export const TICKET_LOGGER_DRAFT_ID = 'ticketLogger'
export const CREATE_NEW_BATCH_PARAM = 'newBatch'
export const CREATE_NEW_CONTACT_PARAM = 'newContact'
export const CREATE_NEW_GROUP_PARAM = 'newGroup'
